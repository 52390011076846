import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Container } from '../components';
import useIsDesktop from '../lib/isDesktop';
import { InsightsContent, InsightsPageHeader, InsightPageExcerpt } from './styles';
import { IArticleGroup } from 'queries/articles';
import { stripHtml } from 'string-strip-html';
import { InsightHeaderImage } from '../components/InsightListing/styles';
import Fade from 'react-reveal/Fade';

const Insights = ({ pageContext }) => {
  const isDesktop = useIsDesktop();

  const article: IArticleGroup = pageContext.node;

  return (
    <Layout isDesktop={isDesktop} title={article.title}>
      <Container flexDirection={'column'} maxWidth="900px">
        <InsightsPageHeader>
          <Fade>
            {article.title}
          </Fade>
        </InsightsPageHeader>
        <InsightPageExcerpt>
          <Fade>
            {stripHtml(article.excerpt).result}
          </Fade>
        </InsightPageExcerpt>
        {article.featuredImage ? <InsightHeaderImage src={article.featuredImage.node.sourceUrl} /> : null}
        <Fade>
          <InsightsContent dangerouslySetInnerHTML={{ __html: article.content ?? '' }} />
        </Fade>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(format: MARKDOWN)
      frontmatter {
        path
        title
      }
    }
  }
`;

export default Insights;
